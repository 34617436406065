import React from 'react'
import PropTypes from 'prop-types'
import { Box, Label, Input, Textarea, Button, Message, Spinner } from 'theme-ui'

/**
 * How to enable form integration:
 *
 * 1) Shadow this component
 * 2) Remove the demo attribute from the form tag.
 * 3) Add your action end-point to the form tag.
 * 4) If required by your form API provider, add the hidden input(s).
 *
 * Some recommended serverless form providers:
 * Formspree (https://www.gatsbyjs.com/docs/building-a-contact-form/#formspree)
 * Netlify Form (https://www.gatsbyjs.com/docs/building-a-contact-form/#netlify)
 *
 */

const ContactForm = ({ handleSubmit, submitting, success }) => (
  <form
    onSubmit={handleSubmit}
    method='POST'
    action='https://formsubmit.co/2d47f26bec5542b674055bcd0b015e00'
  >
    {success === true && (
      <Message variant='success'>
        ¡Tu mensaje se ha enviado correctamente! Solemos contestar en un plazo
        de 24/48 horas laborables. Si no fuera así, siempre puedes contactar con
        nosotros por teléfono.
      </Message>
    )}
    {success === false && (
      <Message variant='error'>
        ¡Ups! Algo ha salido mal. Puedes intentar contactar con nosotros por
        teléfono.
      </Message>
    )}
    <Box variant='forms.row'>
      <Box variant='forms.column'>
        <Label htmlFor='contact-form-name'>Nombre</Label>
        <Input type='text' id='contact-form-name' name='name' required />
      </Box>
      <Box variant='forms.column'>
        <Label htmlFor='contact-form-company'>Empresa (si procede)</Label>
        <Input type='text' id='contact-form-company' name='company' />
      </Box>
    </Box>
    <Box variant='forms.row'>
      <Box variant='forms.column'>
        <Label htmlFor='contact-form-email'>Email</Label>
        <Input type='email' id='contact-form-email' name='email' required />
      </Box>
      <Box variant='forms.column'>
        <Label htmlFor='contact-form-phone'>
          Número de teléfono (opcional)
        </Label>
        <Input type='tel' id='contact-form-phone' name='phone' />
      </Box>
    </Box>
    <Box variant='forms.row'>
      <Label htmlFor='contact-form-subject'>Asunto</Label>
      <Input type='text' id='contact-form-subject' name='subject' required />
    </Box>
    <Box variant='forms.row'>
      <Label htmlFor='contact-form-message'>Tu mensaje</Label>
      <Textarea name='message' id='contact-form-message' />
    </Box>
    <Button
      variant={success || submitting ? 'disabled' : 'primary'}
      disabled={success || submitting}
      type='submit'
      required
    >
      Enviar formulario {submitting && <Spinner size='20' />}
    </Button>
  </form>
)

export default ContactForm

ContactForm.propTypes = {
  handleSubmit: PropTypes.func,
  submitting: PropTypes.bool,
  success: PropTypes.bool
}
