import React from 'react'
import { Layout, Stack, Main, Sidebar } from '@layout'
import PageTitle from '@components/PageTitle'
import Divider from '@components/Divider'
import Seo from '@widgets/Seo'
import ContactForm from '@widgets/ContactForm'
import ContactInfo from '@widgets/ContactInfo'
import { Text } from 'theme-ui'
import Commitment from '@widgets/Commitment'
import CookieConsent, {Cookies} from 'react-cookie-consent'

const PageContact = props => (
  <Layout {...props}>
    <CookieConsent
        enableDeclineButton
          location="bottom"
          buttonText="Aceptar"
          declineButtonText="Declinar"
          cookieName="gatsby-gdpr-google-tagmanager"
          onAccept={() => {
            Cookies.set('gatsby-gdpr-google-tagmanager', 'true');
            window.location.reload();
          }}
          onDecline={() => {
            Cookies.set('gatsby-gdpr-google-tagmanager', 'false');
            window.location.reload();
          }}>
        Este sitio utiliza cookies para mejorar la experiencia de usuario.
        </CookieConsent>
    <Seo title='Contactar con Más Madera Zaragoza' />
    <Divider />
    <Stack>
      <Main>
        <PageTitle
          header='Contacta con nosotros'
          subheader='¿Tienes dudas o quieres que realicemos un presupuesto? Rellena el formulario, llámanos o envíanos un email y te atenderemos. Recuerda que no realizamos reparaciones en domicilios privados y que es posible que en momentos puntuales no podamos hacernos cargo de las llamadas por encontrarnos de montaje.'
        />
        <Divider />
        <ContactForm />
      </Main>
      <Sidebar>
        {/* <Commitment /> */}
        <Divider />
        <ContactInfo />
      </Sidebar>
    </Stack>
  </Layout>
)

export default PageContact
